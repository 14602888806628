import React, { Fragment, useContext, useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Layout from "../../Layout"
import Container from "../../Layout/Container"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"

import {
  GATSBY_SAXENDA_EMAIL,
  GATSBY_SAXENDA_CONTACT_NUMBER,
} from "gatsby-env-variables"

import styles from "../../Elements/utils/elements.module.scss"

const EpharmacyComplete = ({ pageContext }) => {
  const { epharmacyDispatch } = useContext(EpharmacyContext)

  useEffect(() => {
    epharmacyDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
  }, [epharmacyDispatch])

  const { module, nextPath } = pageContext

  return (
    <Layout
      title={module?.title || "Thank you!"}
      seoTitle={module?.seoTitle}
      pageContext={pageContext}
    >
      <Container isCentered>
        <div className={classNames("columns", ["column"])}>
          <div className="column">
            <Fragment>
              <p className="mb-2">
                Thank you for ordering from the Liraglutide (Saxenda<sup>®</sup>
                ) Patient Support Program.
              </p>
              <p className="mb-2">
                Our health coordinators will review your order for verification.
                You will receive an SMS confirming your order details and
                eligibility within two working days.
              </p>
              <p className="mb-2 notification is-success px-1 has-text-centered is-light has-text-black">
                Feel free to reach us at{" "}
                <a href="#" className="has-text-secondary has-text-weight-bold">
                  {GATSBY_SAXENDA_EMAIL}
                </a>{" "}
                or{" "}
                <a
                  href={`mailto:${GATSBY_SAXENDA_EMAIL}`}
                  className="has-text-secondary has-text-weight-bold"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {GATSBY_SAXENDA_CONTACT_NUMBER}
                </a>{" "}
                for any questions.
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link
            to={nextPath}
            className={classNames(
              `button-size mb-1 is-secondary`,
              styles["heroCard__links"],
              styles["secondary_button"]
            )}
          >
            Back to Home Page
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default EpharmacyComplete
